import React, { useState, useEffect } from "react";

import IndustryChartHeader from "../components/IndustryChartHeader";
import FlowChart from "../components/FlowChart";
import Header from "../components/Header";
import Layout from "../components/Layout";
import Select from "../components/Select";
import Footer from "../components/Footer";
import IndustryDownload, {
  IndustryDownloadContainer,
} from "../components/IndustryDownload";

import {
  getIndustryNames,
  getYears,
  getJobChangesForIndustry,
} from "../util/overview";

const industryNames = getIndustryNames();
const years = getYears();

const IndustryPage = ({ location }) => {
  const [industry, setIndustry] = useState(industryNames[0]);
  const [timeframe, setTimeframe] = useState(years[0]);
  const [percentView, setPercentView] = useState(true);

  const jobChanges = getJobChangesForIndustry(industry, timeframe);

  useEffect(() => {
    if (location.state) {
      const { industry, timeframe } = location.state;

      if (industry) {
        setIndustry(industry);
      }

      if (timeframe) {
        setTimeframe(timeframe);
      }
    }
  }, [location, setIndustry]);

  return (
    <Layout>
      <Header>
        <h1>Career Change Tracker</h1>

        <IndustryDownloadContainer>
          <p className="type-30">
            <IndustryDownload industry={industry} />
            Explore talent movements in the{" "}
            <span className="nowrap-xl">
              <Select
                id="industry_select"
                value={industry}
                onChange={(event) => setIndustry(event.target.value)}
                options={industryNames.map((name) => ({
                  label: name,
                  value: name,
                }))}
              />{" "}
              industry for{" "}
              <Select
                id="timeframe_select"
                value={timeframe}
                onChange={(event) => setTimeframe(event.target.value)}
                options={years.map((year) => ({
                  label: year,
                  value: year,
                }))}
              />
            </span>
          </p>
        </IndustryDownloadContainer>

        <IndustryChartHeader
          industry={industry}
          timeframe={timeframe}
          jobChanges={jobChanges}
          percentView={percentView}
          setPercentView={setPercentView}
        />
      </Header>

      <div className="container">
        <FlowChart
          blank={jobChanges === 0}
          industry={industry}
          timeframe={timeframe}
          setIndustry={setIndustry}
          percentView={percentView}
        />

        <p className="color-black type-20 fw-bold">
          How can you apply these insights to your hiring strategy? Get
          recruitment and retention advice in our{" "}
          <a
            className="link-green"
            target="_blank"
            rel="noreferrer"
            href="https://www.totaljobs.com/recruiters/career-change-factsheets/?WT.mc_id=E_A_BA_CCT"
          >
            industry factsheet
          </a>
          .
        </p>
      </div>

      <Footer />
    </Layout>
  );
};

export default IndustryPage;

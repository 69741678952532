import { useEffect, useState } from "react";

export const useAnimationCounter = (limit, delay) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let timeout;

    if (count < limit) {
      timeout = setTimeout(() => {
        setCount(count + 1);
      }, delay);
    }

    return () => clearTimeout(timeout);
  }, [count, limit, delay, setCount]);

  return count;
};

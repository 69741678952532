import React from "react";
import { withSize } from "react-sizeme";
import { getIndustryForYear, getOverviewDomain } from "../../util/overview";
import { useMobileView, useSmallView } from "../../hooks/useMobileView";
import { useAnimationCounter } from "../../hooks/useAnimationCounter";
import Snake from "./Snake";
import Labels from "./Labels";
import Bars from "./Bars";

import styles from "./styles.module.scss";

const PADDING = 50;
const RADIUS = [25, 100];
const MOBILE_RADIUS = [20, 50];

const FlowChart = ({
  size,
  industry,
  timeframe,
  setIndustry,
  percentView,
  blank = false,
}) => {
  const { width } = size;
  const isMobileView = useMobileView();
  const isSmallView = useSmallView();
  const animationCount = useAnimationCounter(8, 250);
  const data = getIndustryForYear(industry, timeframe);

  const domain = getOverviewDomain();

  const height = isMobileView ? 500 : 450;

  const radius = isSmallView ? MOBILE_RADIUS : RADIUS;

  const xPositions = [
    PADDING + radius[1],
    width / 2,
    width - PADDING - radius[1],
  ];

  const yPositions = [radius[1], height / 2, height - radius[1]];

  return (
    <div className={styles.container}>
      <div className={styles.chart}>
        <Snake
          data={data}
          xPositions={xPositions}
          yPositions={yPositions}
          radius={radius}
          domain={domain}
          blank={blank}
          height={height}
          isMobileView={isMobileView}
          width={width}
          animationCount={animationCount}
        />

        <Labels
          industry={industry}
          data={data}
          xPositions={xPositions}
          yPositions={yPositions}
          percentView={percentView}
          blank={blank}
          isMobileView={isMobileView}
          animationCount={animationCount}
        />
      </div>

      <Bars
        industry={industry}
        timeframe={timeframe}
        setIndustry={setIndustry}
        blank={blank}
        percentView={percentView}
        isMobileView={isMobileView}
      />
    </div>
  );
};

export default withSize()(FlowChart);

import React, { useState } from "react";
import { scaleLinear } from "d3-scale";
import { extent } from "d3-array";
import cn from "classnames";
import IndustryBar from "./IndustryBar";

import {
  getInflowsForIndustry,
  getOutflowsForIndustry,
} from "../../../util/flow";

import styles from "./styles.module.scss";

const Bars = ({
  industry,
  timeframe,
  setIndustry,
  blank = false,
  percentView = true,
  isMobileView = false,
}) => {
  const [showMore, setShowMore] = useState(false);
  const [showMoreOut, setShowMoreOut] = useState(false);

  const inflows = getInflowsForIndustry(industry, timeframe);
  const outflows = getOutflowsForIndustry(industry, timeframe);
  const flows = inflows.concat(outflows);

  const scale = scaleLinear()
    .domain(extent(flows, ({ percentage }) => percentage))
    .range([0, 100]);

  const onShowMoreInflow = () => {
    setShowMore(!showMore);
  };

  const onShowMoreOutflow = () => {
    if (isMobileView) {
      setShowMoreOut(!showMoreOut);
    } else {
      setShowMore(!showMore);
    }
  };

  const showMoreOutflow = isMobileView ? showMoreOut : showMore;

  return (
    <div
      className={cn(styles.container, {
        [styles.blank]: blank,
        [styles.mobile]: isMobileView,
      })}
    >
      <div className="row">
        <div className="col-11 col-md-5">
          {blank === false && (
            <>
              <p className={styles.label}>
                <span className="nowrap"><strong>Inflows:</strong> Top industries new</span>{" "}
                <span className="nowrap">{industry} workers moved from</span>
              </p>
              <p className="color-grey type-16">
                Select an industry to explore further
              </p>
            </>
          )}

          {inflows
            .slice(0, showMore ? 10 : 5)
            .map(({ rank_name, rank_value, percentage }, index) => (
              <IndustryBar
                key={index}
                label={rank_name}
                value={scale(percentage)}
                setIndustry={setIndustry}
                color="to"
                blank={blank}
                percentage={percentage}
                actual={rank_value}
                percentView={percentView}
              />
            ))}
        </div>

        {blank === false && isMobileView && (
          <div className="col-12 mb-4">
            <button className={styles.showNext} onClick={onShowMoreInflow}>
              {showMore
                ? "Hide five industries"
                : "Show the next five industries"}
            </button>
          </div>
        )}
        <div className="col-11 col-md-5 ms-auto">
          {blank === false && (
            <>
              <p className={cn(styles.label, styles.right, "text-end")}>
                <span className="nowrap"><strong>Outflows:</strong> Top industries</span>{" "}
                <span className="nowrap">{industry} workers moved to</span>
              </p>
              <p className="color-grey type-16 text-end">
                Select an industry to explore further
              </p>
            </>
          )}

          {outflows
            .slice(0, showMoreOutflow ? 10 : 5)
            .map(({ rank_name, rank_value, percentage }, index) => (
              <IndustryBar
                key={index}
                label={rank_name}
                value={scale(percentage)}
                setIndustry={setIndustry}
                color="from"
                blank={blank}
                percentage={percentage}
                actual={rank_value}
                percentView={percentView}
                flip
              />
            ))}
        </div>
      </div>

      {blank === false && (
        <button className={styles.showNext} onClick={onShowMoreOutflow}>
          {isMobileView
            ? showMoreOut
              ? "Hide five industries"
              : "Show the next five industries"
            : showMore
            ? "Hide five industries"
            : "Show the next five industries"}
        </button>
      )}
    </div>
  );
};

export default Bars;

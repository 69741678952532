import React from "react";
import styles from "./styles.module.scss";

import DownloadIcon from "../../images/icons/download.inline.svg";

const IndustryDownload = ({ industry }) => (
  <a
    href="https://www.totaljobs.com/recruiters/career-change-factsheets/?WT.mc_id=E_A_BA_CCT"
    target="_blank"
    rel="noreferrer"
    className={styles.link}
  >
    <span className={styles.label}>Download factsheet for this industry</span>

    <span className={styles.circle} />
    <span className={styles.arrow}>
      <DownloadIcon />
    </span>
  </a>
);

export const IndustryDownloadContainer = ({ children }) => (
  <div className={styles.container}>{children}</div>
);

export default IndustryDownload;

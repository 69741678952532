import React from "react";

const Icon = ({ icon, size }) => {
  const SvgIcon = icon;
  const width = size * 2 - 10;

  return (
    <g style={{ transform: `translate(-${width / 2}px, -${width / 2}px)` }}>
      <SvgIcon width={width} height={width} />
    </g>
  );
};

export default Icon;

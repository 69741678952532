import React from "react";
import cn from "classnames";
import CountUp from "react-countup";

import styles from "./styles.module.scss";

const Labels = ({
  industry,
  data,
  xPositions,
  yPositions,
  percentView = true,
  blank,
  isMobileView = false,
  numberFontSize = false,
  labelFontSize = false,
  labelLineHeight = false,
  animationCount,
  instant = false,
}) => (
  <div className={styles.labels}>
    {blank ? (
      <div className={styles.blank}>
        Sorry, we don’t have data available for this view yet (because of the
        time it takes people to update their online profiles and CVs after
        changing jobs). Please check back in a few months’ time as we update our
        data quarterly.
      </div>
    ) : (
      <>
        <div
          className={cn(`type-34 fw-bold color-to`, styles.label, {
            [styles.mobile]: isMobileView,
          })}
          style={{
            left: isMobileView ? 0 : xPositions[0],
            top: isMobileView ? yPositions[0] : 0,
            fontSize: numberFontSize,
          }}
        >
          {percentView ? (
            <CountUp
              end={animationCount > 0 || instant ? data.to_perc : 0}
              suffix="%"
              duration={0.5}
              preserveValue
            />
          ) : (
            <CountUp end={data.to} duration={0.5} separator="," preserveValue />
          )}

          <span
            className={styles.subtitle}
            style={{
              fontSize: labelFontSize,
              lineHeight: labelLineHeight,
            }}
          >
            moved into
            <br />
            {industry}
          </span>
        </div>
        <div
          className={cn(`type-34 fw-bold color-retained`, styles.label, {
            [styles.mobile]: isMobileView,
          })}
          style={{
            left: isMobileView ? 0 : xPositions[1],
            top: isMobileView ? yPositions[1] : 0,
            fontSize: numberFontSize,
          }}
        >
          {percentView ? (
            <CountUp
              end={animationCount > 2 || instant ? data.retained_perc : 0}
              suffix="%"
              duration={0.5}
              preserveValue
            />
          ) : (
            <CountUp
              end={data.retained}
              duration={0.5}
              separator=","
              preserveValue
            />
          )}
          <span
            className={styles.subtitle}
            style={{
              fontSize: labelFontSize,
              lineHeight: labelLineHeight,
            }}
          >
            moved within
            <br />
            {industry}
          </span>
        </div>
        <div
          className={cn(`type-34 fw-bold color-from`, styles.label, {
            [styles.mobile]: isMobileView,
          })}
          style={{
            left: isMobileView ? 0 : xPositions[2],
            top: isMobileView ? yPositions[2] : 0,
            fontSize: numberFontSize,
          }}
        >
          {percentView ? (
            <CountUp
              end={animationCount > 4 || instant ? data.from_perc : 0}
              suffix="%"
              duration={0.5}
              preserveValue
            />
          ) : (
            <CountUp
              end={data.from}
              duration={0.5}
              separator=","
              preserveValue
            />
          )}
          <span
            className={styles.subtitle}
            style={{
              fontSize: labelFontSize,
              lineHeight: labelLineHeight,
            }}
          >
            moved out of
            <br />
            {industry}
          </span>
        </div>
      </>
    )}
  </div>
);

export default Labels;

import React from "react";
import cn from "classnames";

import styles from "./styles.module.scss";

const ToggleButton = ({ onClick, value }) => (
  <button className={styles.button} onClick={onClick}>
    <div className={cn(styles.highlight, { [styles.left]: value })} />
    <span className={cn(styles.icon, { [styles.on]: value })}>%</span>
    <span className={cn(styles.icon, { [styles.on]: !value })}>#</span>
  </button>
);

export default ToggleButton;

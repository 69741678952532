import { useEffect, useState } from "react";
import { mobileBreakpoint, smallBreakpoint } from "../config.json";

export const useMobileView = () => {
  const [isMobileView, setMobileView] = useState(false);

  useEffect(() => {
    const listener = () => {
      const clientWidth = document.documentElement.clientWidth;

      if (clientWidth <= mobileBreakpoint) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    };

    listener();

    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, []);

  return isMobileView;
};

export const useSmallView = () => {
  const [isSmallView, setSmallView] = useState(false);

  useEffect(() => {
    const listener = () => {
      const clientWidth = document.documentElement.clientWidth;

      if (clientWidth <= smallBreakpoint) {
        setSmallView(true);
      } else {
        setSmallView(false);
      }
    };

    listener();

    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, []);

  return isSmallView;
};

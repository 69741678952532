import React from "react";
import numeral from "numeral";
import Box from "../Box";
import ToggleButton from "../ToggleButton";
import styles from "./styles.module.scss";

const IndustryChartHeader = ({
  industry,
  timeframe,
  jobChanges,
  percentView,
  setPercentView,
}) => (
  <Box>
    {jobChanges > 0 ? (
      <>
        <span className={styles.label}>
          <strong>{industry}</strong> saw over{" "}
          <strong>{numeral(jobChanges).format(0, 0)} job changes</strong> in{" "}
          <span className={styles.timeframe}>{timeframe}</span>
        </span>

        <ToggleButton
          onClick={() => setPercentView(!percentView)}
          value={percentView}
        />
      </>
    ) : (
      <span>&nbsp;</span>
    )}
  </Box>
);

export default IndustryChartHeader;

import React from "react";
import cn from "classnames";
import styles from "./styles.module.scss";

const Circle = ({ fill, x, y, r, blank, icon }) => {
  return (
    <g className={cn(styles.container, { [styles.blank]: blank })}>
      <circle
        fill={fill}
        cx={x}
        cy={y}
        className={styles.circle}
        style={{ r, opacity: blank ? 0.3 : 1 }}
      />

      <g
        className={styles.opacity}
        style={{
          transform: `translate(${x}px, ${y}px)`,
          opacity: blank ? 0 : 1,
        }}
      >
        {icon}
      </g>
    </g>
  );
};

export default Circle;

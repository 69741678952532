import React from "react";
import cn from "classnames";

import Bar from "../../../Bar";
import ChevronIcon from "../../../../images/icons/chevron.inline.svg";

import styles from "./styles.module.scss";

const IndustryBar = ({
  label,
  value,
  setIndustry,
  color,
  blank,
  percentage,
  actual,
  percentView,
  flip = false,
}) => {
  const empty = blank || label === "NA";

  const onClick = () => {
    if (!empty) {
      setIndustry(label);
    }
  };

  return (
    <button
      onClick={onClick}
      className={cn(styles.button, {
        [styles.flip]: flip,
        [styles.empty]: empty,
      })}
    >
      <span className={styles.buttonLabel} style={{ opacity: empty ? 0 : 1 }}>
        {empty ? (
          <span>&nbsp;</span>
        ) : (
          <span>
            {label} <ChevronIcon />
          </span>
        )}
      </span>

      <div className={cn({ [styles.blank]: blank })}>
        <Bar
          className={`bg-${color} faded`}
          value={blank ? 100 : value}
          height={20}
          pill={`bg-${color}`}
          flip={flip}
          percentLabel={empty ? "" : percentView ? percentage : actual}
          percentLabelSuffix={percentView ? "%" : ""}
          decimals={percentView ? 1 : 0}
          instant
        />
      </div>
    </button>
  );
};

export default IndustryBar;

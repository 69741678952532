import React from "react";
import { scaleLinear } from "d3-scale";
import { motion } from "framer-motion";

import Circle from "./Circle";
import Icon from "./Icon";

import MovedOutIcon from "../../../images/icons/movedOut.inline.svg";
import MovedIntoIcon from "../../../images/icons/movedInto.inline.svg";
import MovedWithinIcon from "../../../images/icons/movedWithin.inline.svg";
import styles from "./styles.module.scss";

const CURVE = -200;

const Snake = ({
  data,
  width,
  xPositions,
  yPositions = [],
  radius,
  blank = false,
  domain = [0, 100],
  height = 450,
  isMobileView = false,
  animationCount,
  instant = false,
}) => {
  const y = height / 2;
  const rScale = scaleLinear().domain(domain).range(radius);
  let [leftX, middleX, rightX] = xPositions;
  const [topY, middleY, bottomY] = yPositions;

  const differenceLeft = (middleX - leftX) / 2;
  const differenceHeight = (middleY - topY) / 2;

  const leftR = rScale(blank ? domain[0] : data.to_perc);
  const middleR = rScale(blank ? domain[0] : data.retained_perc);
  const rightR = rScale(blank ? domain[0] : data.from_perc);

  const xAngle = (radius, position, angle) => {
    return position + radius * Math.cos((angle * Math.PI) / 180);
  };

  const yAngle = (radius, position, angle) => {
    return position + radius * Math.sin((angle * Math.PI) / 180);
  };

  const leftPath = () => {
    const rotate = isMobileView ? 90 : 0;
    const startingAngle = 225 + rotate;

    if (isMobileView) {
      return `
      M ${xAngle(leftR, rightX, startingAngle)}
      ${yAngle(leftR, topY, startingAngle)}

      Q ${xAngle(leftR, rightX, startingAngle) - CURVE / 2}
      ${topY + differenceHeight},

      ${xAngle(middleR, rightX, startingAngle + 90)}
      ${yAngle(middleR, middleY, startingAngle + 90)}

      L ${xAngle(middleR, rightX, startingAngle - 90)}
      ${yAngle(middleR, middleY, startingAngle - 90)}

      Q ${xAngle(leftR, rightX, startingAngle - 180) - CURVE / 2}
      ${topY + differenceHeight},

      ${xAngle(leftR, rightX, startingAngle - 180)}
      ${yAngle(leftR, topY, startingAngle - 180)}

      Z
    `;
    } else {
      return `
      M ${xAngle(leftR, leftX, startingAngle)}
      ${yAngle(leftR, y, startingAngle)}

      Q ${leftX + differenceLeft} ${yAngle(leftR, y, startingAngle) + CURVE},
      ${xAngle(middleR, middleX, startingAngle + 90)}
      ${yAngle(middleR, y, startingAngle + 90)}

      L ${xAngle(middleR, middleX, startingAngle - 90)}
      ${yAngle(middleR, y, startingAngle - 90)}

      Q ${leftX + differenceLeft}
      ${yAngle(leftR, y, startingAngle - 180) + CURVE},
      ${xAngle(leftR, leftX, startingAngle - 180)}
      ${yAngle(leftR, y, startingAngle - 180)}

      Z
    `;
    }
  };

  const rightPath = () => {
    const rotate = isMobileView ? 90 : 0;
    const startingAngle = 135 + rotate;

    if (isMobileView) {
      return `
      M ${xAngle(middleR, rightX, startingAngle)}
      ${yAngle(middleR, middleY, startingAngle)}

      Q ${xAngle(middleR, rightX, startingAngle - 90) + CURVE / 2}
      ${middleY + differenceHeight},

      ${xAngle(rightR, rightX, startingAngle - 90)}
      ${yAngle(rightR, bottomY, startingAngle - 90)}

      L ${xAngle(rightR, rightX, startingAngle + 90)}
      ${yAngle(rightR, bottomY, startingAngle + 90)}

      Q ${xAngle(middleR, rightX, startingAngle + 90) + CURVE / 2}
      ${middleY + differenceHeight},

      ${xAngle(middleR, rightX, startingAngle + 180)}
      ${yAngle(middleR, middleY, startingAngle + 180)}

      Z
      `;
    } else {
      return `
      M ${xAngle(middleR, middleX, startingAngle)}
      ${yAngle(middleR, y, startingAngle)}

      Q ${middleX + differenceLeft}
      ${yAngle(rightR, y, startingAngle - 90) - CURVE},
      ${xAngle(rightR, rightX, startingAngle - 90)}
      ${yAngle(rightR, y, startingAngle - 90)}

      L ${xAngle(rightR, rightX, startingAngle + 90)}
      ${yAngle(rightR, y, startingAngle + 90)}

      Q ${middleX + differenceLeft}
      ${yAngle(rightR, y, startingAngle + 90) - CURVE},
      ${xAngle(middleR, middleX, startingAngle + 180)}
      ${yAngle(middleR, y, startingAngle + 180)}

      Z
      `;
    }
  };

  const animation = {};

  if (animationCount > 1 || instant) {
    if (isMobileView) {
      animation.height = bottomY + rightR - topY + leftR;
    } else {
      animation.width = rightX + rightR - leftX + leftR;
    }
  }

  return (
    <svg className={styles.svg} style={{ height }}>
      <clipPath id="pathClip">
        <motion.rect
          transition={{ duration: instant ? 0 : 1.2, ease: "linear" }}
          x={isMobileView ? 0 : leftX - leftR}
          y={isMobileView ? topY - leftR : 0}
          width={isMobileView ? 2000 : 0}
          height={isMobileView ? 0 : height}
          animate={animation}
        />
      </clipPath>

      {blank === false && (
        <g clipPath="url(#pathClip)">
          <motion.path
            fill="#FBDACB"
            transition={{ duration: 0.5, ease: "easeOut" }}
            animate={{ d: leftPath() }}
          />
          <motion.path
            fill="#DEE7F4"
            transition={{ duration: 0.5, ease: "easeOut" }}
            animate={{ d: rightPath() }}
          />
        </g>
      )}

      <Circle
        fill="#F06C30"
        x={isMobileView ? rightX : leftX}
        y={isMobileView ? topY : y}
        r={animationCount > 0 || instant ? leftR : 0}
        blank={blank}
        icon={<Icon icon={MovedIntoIcon} size={radius[0]} />}
      />

      <Circle
        fill="#8C0E51"
        x={isMobileView ? rightX : middleX}
        y={isMobileView ? middleY : y}
        r={animationCount > 2 || instant ? middleR : 0}
        blank={blank}
        icon={<Icon icon={MovedWithinIcon} size={radius[0]} />}
      />

      <Circle
        fill="#0043A6"
        x={isMobileView ? rightX : rightX}
        y={isMobileView ? bottomY : y}
        r={animationCount > 4 || instant ? rightR : 0}
        blank={blank}
        icon={<Icon icon={MovedOutIcon} size={radius[0]} />}
      />
    </svg>
  );
};

export default Snake;
